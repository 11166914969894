@import url('https://fonts.googleapis.com/css?family=Homenaje&display=swap');

body
{
  width: 100%;
  height: 100%;
  background-image:url(images/image.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  font-family: 'Homenaje', sans-serif;
  margin: 0;
  padding: 0;
}
#app{
  min-width: 100%;
  min-height: 100%;
}

/*.btn{
  color: #ecf0f1;
  width: 80%;
  padding: 3px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  margin-top:30px;
  margin-left: 10%;
  margin-right: 10%;
  letter-spacing:0.2em;
  font-size:16px;
  transition: all 0.3s;
  margin-bottom: 12px;
}*/
.btn2{
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}
.loginImageBtn{
  height: 80px;
}
.userProImageBtn{
  height: 60px;
}
.btn:hover{
  background: #FF4D41;
  cursor: pointer;
}

.btnAppleLogin{
  background: #000;
  color: #ecf0f1;
  width: 45px;
  height: 45px;
  min-height: 40px !important;
  border-radius: 25px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 30px;
  margin-right: 10%;
  letter-spacing:0.2em;
  font-size:25px;
  transition: all 0.3s;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 4px 0px;
}
.btnAppleLogin:hover{
  background: #FFF;
  cursor: pointer;
  color: black;
}
.btnAppleLogin:hover i{
  color: #fff;
}

.loginOptionText1{
  color:  green;
  align-self: center;
  font-family: "LilyScriptOne";
  font-size: 30px;
}

.loginOptionText2{
  color:  green;
  align-self: center;
  font-family: "LilyScriptOne";
  font-size: 20px;
  margin-top: 15px;
}

.forgotpassword{
  color:  black;
  align-self: center;
  font-family: "LilyScriptOne";
  font-size: 16px;
  margin-top: 15px;
}

.loginOptionText3{
  color:  green;
  align-self: center;
  font-family: "LilyScriptOne";
  font-size: 20px;
  margin-bottom: 15px;
}

.userIDText{
  color: white;
  align-self: flex-end;
  align-items: start;
  margin-right: 20px;
}

.btnFacebookLogin{
  background: #3C5999;
  color: #ecf0f1;
  width: 45px;
  height: 45px;
  min-height: 40px !important;
  border-radius: 25px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 30px;
  letter-spacing:0.2em;
  font-size:25px;
  transition: all 0.3s;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 4px 0px;
}
.btnFacebookLogin:hover{
  background: #FFF;
  cursor: pointer;
  color: black;
}
.btnFacebookLogin:hover i{
  color: #fff;
}
.otherLoginButtons{
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.box{
  width: 640px;
  height: 480px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  border-radius:30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom:20px;
  padding-top:20px;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 4px 0px;
}

.profileBox{
  width: 640px;
  height: auto;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  border-radius:30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom:20px;
  padding-top:20px;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 4px 0px;
  overflow: hidden;
}
.box h3{
  color: #ecf0f1;
  margin-left: 10%;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.2em;
}

.loginContainer{
  min-height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.metamaskIcon{
  width: 30px;
  height: 30px;
}
.walletName{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.walletConnectBox{
  width: 400px;
  height: 300px;
  background: rgba(47, 54, 64, 0.5);
  margin: 0 auto;
  border-radius:12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom:20px;
  padding-top:20px;
}

.btnMetamaskConnect{
  background: #FB6549;
  color: #ecf0f1;
  width: 80%;
  padding: 3px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  margin-top:30px;
  margin-left: 10%;
  margin-right: 10%;
  letter-spacing:0.2em;
  font-size:16px;
  transition: all 0.3s;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 4px 0px;
}

.btnMetamaskConnect:hover{
  background: #FF4D41;
  cursor: pointer;
}

.btnDepositAndWithdraw{
  background: #FB6549;
  color: #ecf0f1;
  width: 120px !important;
  height: 50px;
  padding: 3px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  margin-top:30px;
  letter-spacing:0.2em;
  font-size:16px;
  transition: all 0.3s;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 4px 0px;
}

.btnDepositAndWithdraw:hover{
  background: #FF4D41;
  cursor: pointer;
}

.input{
  font-family:inherit;
  background:lightskyblue;
  margin-left: 10%;
  margin-right: 10%;
  width: 75%;
  height: 30px;
  margin-top: 30px;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:10px;
  padding-right:10px;
  border: 1px solid black;
  outline:none;
  transition: all 0.30s ease-in-out;
  letter-spacing:0.15em;
  border-radius: 15px;
  font-size: large;
  text-indent: 15px;
}
.inputFieldLabel{
  color:  green;
  align-self: center;
  font-family: "LilyScriptOne";
  font-size: 20px;
  margin-bottom: 15px;
  margin-left: 10%;
}
.input:focus{
  box-shadow: 0 0 5px rgba(251, 101, 73, 1);
  border: 1px solid rgba(255, 77, 65, 1);
}

.errorMsg h3, .hideErrorMsg h3{
  color: #efefef;
  font-weight:700;
  letter-spacing: 0.27em;
  background: rgba(47, 41, 41, 0.5);
  width:75%;
  padding:10px;
}
.errorMsg h3{
  animation: slideIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.hideErrorMsg h3{
  animation: slideOut 0.9s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}


bodyx{
  font-family: 'Hind Guntur', sans-serif;
  color: #054231;
  display: flex;
  justify-content: center;
  background: #49afa8;
  background-repeat: no-repeat, no-repeat;
  background-position:  200% -40%;
  background-size: 80%;
}

.card{
  width: 640px;
  height: 480px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  border-radius:30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom:20px;
  padding-top:20px;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 4px 0px;
  background: rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat, no-repeat;
  background-position: 120% -5%, 200% -40%;
  background-size: 40%, 80%;
  animation: open .5s;
}
@keyframes open {
  0%  {background-position: 166% -25%, 220% -69%;}
  100%{background-position: 120% -5%, 200% -40%;}
}
form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.field{
  margin: 5px;
  display: flex;
  flex-direction: column;
}
input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  margin-bottom: 25px;
}

.img-wrap{
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}
.img-upload:before{
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}
.img-upload:hover:before{
  opacity: 1;
}
img {
  width: auto;
  height: 100%;
}

label{
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
}

input{
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  transition: 0.2s;
}
input:focus{
  outline: none;
  border: 1px solid #64d488;
}
input::placeholder{
  color: #bebebe;
}
.name{
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
  max-width: 220px;
  overflow-wrap: break-word;
}
.status{
  text-align: center;
  max-width: 220px;
  overflow-wrap: break-word;
}
.submitProfile{
  position: relative;
  color: #054231;
  letter-spacing: 1px;
  margin: 20px;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  border-radius: 10px;
  cursor: pointer;
  border-radius: 25px;
  border: none;
  background: #64d488;
}
.save{
  font-weight: 600;
  left: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 180px;
  box-shadow: 20px 0px 40px 0px  #63d3a6;
}
.cancel{
  font-weight: 600;
  left: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 180px;
  box-shadow: 20px 0px 40px 0px  #63d3a6;
}
.edit{
  color: #fff;
  width: 180px;
}

.submitProfile:hover{
  left: 0;
  color: #fff;
  width: 190px;
  box-shadow: 0px 0px 20px 0px  #63d3a6;
}
button:focus{
  outline: none;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.fa-instagram{
  position: absolute;
  color: #79f1a4;
  top: 3%;
  right: 2%;
  font-size: 38px;
  background-color: #df10;
  border: #df10;
}
.fa-instagram:hover{
  font-size: 42px;
  color: #caff81;
  transition: all .1s linear;
  cursor: pointer;
}

.fa-instagramLeft{
  position: absolute;
  color: #79f1a4;
  top: 3%;
  left: 2%;
  font-size: 38px;
  background-color: #df10;
  border: #df10;
}
.fa-instagramLeft:hover{
  font-size: 42px;
  color: #caff81;
  transition: all .1s linear;
  cursor: pointer;
}

.tabList{
  text-align: center;
  background: transparent;
}
.tabPanel{
  text-align: center;
}
.react-tabs__tab:focus:after{
  background: none !important;
}



@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate3d(-20rem, 0, 500rem);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-20rem, 0, 500rem);
  }
}

@font-face {
  font-family: "LilyScriptOne";
  src: local("LilyScriptOne"),
    url("./fonts/LilyScriptOne-Regular.ttf") format("truetype");
}
